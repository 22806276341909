require('./bootstrap');

$(function() {
    $(".hamburger").click(function() {
        $(this).toggleClass("is-active");
        $(".mainnav").toggleClass("nav-open");
        $(".nav-popup").toggleClass("show");
    });
    $(window).on("scroll touchmove", function () {
        $('.mainnav').toggleClass('nav-scrolled', $(document).scrollTop() > 80);
        $('body').toggleClass('scrolled', $(document).scrollTop() > 80);
    });

    setTimeout(() => {
        if(!sessionStorage.getItem('popup_shown') || sessionStorage.getItem('popup_shown')==null){
            $('#infoModal').modal('show')
            sessionStorage.setItem('popup_shown', true);
        }
    }, "2500");
});
